<template>

	<div class="Pagination" :class="whatConfig" :style="{'position': PagPosition}">
		<div 
		v-for="page in maxPagination" 
		:key="page" 
		@click="emitPagination(page)"
		class="Pagination--element"
		:class="page === activePagination ? 'active ' + $route.name : ''"
		> 
		<p>{{ page }} </p>
		</div>
	</div>
</template>

<script>
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

export default {

  name: 'Pagination',
  props: ['activePagination', 'maxPagination'],
	data: function(){
		return {
			PagPosition: 'absolute'
		}
  },
  computed: {
    ClientFormat: function(){ return this.$client.state.format},
    ClientDevice: function(){ return this.$client.state.device},

    whatConfig: function(){
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        return 'pagination-mobile'
      }else {
        return ''
      }
    },

  },
  methods: {
		emitPagination(page) {
			// console.log(page)
			this.$emit('set-pagination', { page })
		},

		setPosition: function(){
			// console.log('setPosition')
			if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
				// console.log(this.ClientDevice)
				if ( this.$el.parentNode.getBoundingClientRect().bottom > window.innerHeight){
					// console.log('CA DEPASSE')
					this.PagPosition = 'relative'
				}else {
					this.PagPosition = 'absolute'
				}
			}
		}
	},
  mounted(){

		const ResizeObserver = window.ResizeObserver || Polyfill;

    let observeHeight = () => {
      const resizeObserver = new ResizeObserver(() => {
				// console.log('CA BOUGE', this.$el.parentNode.getBoundingClientRect().bottom)
				this.setPosition()
      });
     resizeObserver.observe(this.$el.parentNode.parentNode);
    }
    observeHeight();
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.Pagination{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;  
	height: calc(1.3 * var(--header-filter-font));
	
	// pour mobile/tablet
	// margin-bottom: var(--esp-med);
	// position: relative;
	// width: 100%;
	// bottom: 0vh;

	// ok pour desktop landscape 
	position: absolute;
	bottom: var(--esp-med);
	width: 100%;


	// background-color: red;
	// transform: translate(-50%, 0);

	&--element{

		text-align: center;
		width: calc(1.3 * var(--header-filter-font));
		font-size: var(--header-filter-font);
		cursor: pointer;

		& p {
			transform: translate(0, 18%);
		}

		&.active{
			cursor: auto;
		}
	}
}

.Pagination.pagination-mobile{
	// pour mobile/tablet
	margin-top: var(--esp-med);
	margin-bottom: var(--esp-med);
	position: relative;
	width: 100%;
	bottom: 0vh;
}

// .Pagination.pagination-mobile{
// 	margin-top: var(--esp-med);
// 	margin-bottom: var(--esp-med);
// 	position: absolute;
// 	width: 100%;
// 	bottom: 0vh;
// }

.Pagination--element.active.art{
  color: var(--first-color);
  background-color: black;
}

.Pagination--element.active.amateur{
  color: var(--second-color);
  background-color: black;
}



</style>











