<template>

  <transition name="fadeView" appear mode="out-in">

  <div class="articles" 
  :class="[this.$route.name, whatConfig]"
  :style="{height: whatHeight()}">

    <Header 
    :title="page.title" 
    :HeaderFilters="HeaderFilters" 
    :filtersTab="page.filters" 
    :activeFilter="activeFilter" 
    @set-filter="setFilter" 
    ></Header>

    <div class="articles--body">

      <template v-if="activeFilter == 'none'">

        <CardArticles :articles="articles" :widthElement="widthElement">
        <Pagination v-if="maxPagination > 0" :activePagination="pagination" :maxPagination="maxPagination" @set-pagination="setPagination"></Pagination>
        </CardArticles>

      </template>

      <template v-if="activeFilter == 'true'">

        <CardArticlesVideo :articles="articlesVideo" :widthElement="widthElementVideo">
        <Pagination v-if="maxPagination > 0" :activePagination="pagination" :maxPagination="maxPagination" @set-pagination="setPagination"></Pagination>
        </CardArticlesVideo>

      </template>
    </div>

    <Scrollbar v-if="this.whatComputConfig != '-mobile'" :parent="this.$route.name"></Scrollbar>

  </div>

  </transition>

</template>

<script>
import Header from '@/components/Header.vue'
import CardArticles from '@/components/CardArticles.vue'
import CardArticlesVideo from '@/components/CardArticlesVideo.vue'
import Pagination from '@/components/Pagination.vue'

import Scrollbar from '@/components/scrollbar/Scrollbar.vue'
import clientConfigs from '@/mixins/clientConfigs.js'

export default {

  name: 'Articles',
  mixins: [clientConfigs],
  data: function () {
   return {
     state: this.$store.state,

     title: '', // non utilisé ? à confirmer
     filters: '', // non utilisé ? à confirmer

     articles: '',
     articlesVideo: '',
     pagination: 1,
     maxPagination: '',
     activeFilter: 'none',

    }
  },
  components: {
    Header,
    CardArticles,
    CardArticlesVideo,
    Pagination,

    Scrollbar
  }, 

  computed: {

    page: function () { return this.$store.state.[this.$route.params.language].[this.$route.name] },

    whatConfig: function(){
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        return 'articles-mobile'
      }else {
        return ''
      }
    },

    HeaderFilters: function(){
      return this.ClientDevice === 'desktop' ? 'HeaderFilters' : 'HeaderFilters'
    },

    widthElement: function(){

      let columns
      if ( this.ClientDevice == 'mobile' ){
        columns = 'two-columns'
      }
      if ( this.ClientDevice == 'tablet' ){
        columns = 'four-columns'
      }
      
      if ( this.ClientDevice == 'desktop' ){

          if (this.ClientFormat == 'portrait'){

            if (this.ClientWidth == 'mobileWidth'){
              columns = 'two-columns'

            }else {
              columns = 'four-columns'
            }
          }

          if (this.ClientFormat == 'landscape'){

            columns = 'four-columns'

            if (this.ClientWidth == 'desktopBig' || this.ClientWidth == 'desktopBigger'){
              columns = 'five-columns'
            }
            if (this.ClientWidth == 'desktopBiggest' || this.ClientWidth == 'desktopMax'){
              columns = 'six-columns'
            }

          }
      }
      return columns
    },
    widthElementVideo: function(){
      if (this.ClientWidth == 'mobileWidth'){
        return 'one-columns'
      }else {
        return 'two-columns'
      }
    }

  },

  methods: {

    whatHeight(){
      // if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
       
      //   let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height');

      //   let pixelTotalHeight = window.innerHeight 
      //   let pixelMargeHeight = margeArticleRaw.split('px')[0]
      //   let pixelContentHeight = window.innerHeight - pixelMargeHeight

      //   let pourentContentHeight = pixelContentHeight * 100 / pixelTotalHeight

      //   return pourentContentHeight + '%'

      // }else {
      //   return ''
      // }
      return ''
    },

    scrollCapture(){
      // console.log('window scroll')
    },

    setFilter(payload) {
      // console.log(payload.filter)
      this.activeFilter = payload.filter
      this.pagination = 1
      this.setArticles(payload.filter)
    },

    setPagination(newPagination){
      this.pagination = newPagination.page
      this.setArticles(this.activeFilter)
    },

    async setArticles(filter) {

      let query = { Category: this.$route.name, Filter: this.activeFilter, Pagination: this.pagination }
      let response = await this.$api.getArticles(query)

      // console.log(response)

      if (filter == 'none'){
        this.maxPagination = response.max
        this.articles = response.content

      }else {
        this.maxPagination = response.max
        this.articlesVideo = response.content
      }

    },
    onResize: function(){
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        // console.log('ARTICLES onResize forceupdate-mobile')
        this.$forceUpdate();
      }
    }
  },

  created() {
   window.scroll(0, 0);
   this.setArticles('none')
  },

  mounted(){
    this.$client.isInit = true        
    window.addEventListener('resize', this.onResize) 
  },

  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }

}
</script>


<style lang="scss">
@import '@/css/variables';
@import '@/css/transition';



// OK AVANT TRANSITION BOUTIQUE
.articles{
  z-index: 2;

  position: absolute;
  min-height: 100vh;

  width: var(--page-width);
  left: calc(var(--marge-page) / 2);

}

.art{
  background-color: var(--first-color);
}

.amateur{
  background-color: var(--second-color)
}


.articles.articles-mobile{
  z-index: 2;
  position: absolute;
  // min-height: calc(100% - var(--menu-header-height));
  height: calc(100% - var(--menu-header-height));
  width: 100vw; 
  left: 0;
  top: var(--menu-header-height);
}


</style>


