<template>
  <div class="CardArticlesVideo">
	<router-link 
		v-bind:to=" '/' + $route.params.language + '/' + article.uid " 
		class="CardArticlesVideo--element" 
		v-for="article in articles" 
		:key="article.title"
		:class="widthElement"
		>
		<div class="CardArticlesVideo--element--cover">
			<video class="CardArticlesVideo--element--cover--img" autoplay loop muted playsinline :poster="article.video_cover[0].poster">
				<source :src="article.video_cover[0].url" type="video/mp4" />
			</video>
		</div>

		<div class="CardArticlesVideo--element--title" v-html="article.title"></div>
		<div class="CardArticlesVideo--element--text" v-html="article.subtitle_cover"></div>
	</router-link>

	<slot></slot>

  </div>
</template>

<script>
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'CardArticlesVideo',
	directives:{
		LazyLoading
  },
  props: [
	'articles',
	'widthElement'
  ],
	created(){
		// console.log('CardArticlesVideo content', this.articles)
		// console.log(this.articles)
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.CardArticlesVideo{
	display: flex;
	flex-wrap: wrap;
	// margin-top: $card-articles-esp;

	// ok pour dekstop landscape
	// margin-bottom: calc(var(--esp-big) + var(--header-filter-font));


	&--element{
		margin-bottom: var(--esp-big);

		&--cover{			
			overflow: hidden;
			width: 100%;

			&--img{
				width: 100%;
				z-index: -1;
				position: relative;
				transform: scale(1.2);
			}
		}

		&--title{
			font-size: var(--card-articles-font-title);	// --card-article--font--title
			line-height: 0.9;
					
			// margin-top: 0.6vw;
			margin-top: calc(0.5 * var(--card-articles-font-text));


			overflow-wrap: anywhere;
			@supports ( hyphens: auto ) {
				hyphens: auto;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
			}
		}
		&--text{
			font-family: 'vd-reg';
			font-size: var(--card-articles-font-text);	// --card-article--font--text
			line-height: 1;

			// margin-top: 0.3vw;
			// margin-top: var(--esp-small);
			margin-top: calc(0.35 * var(--card-articles-font-text));

			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;  
			overflow: hidden;

			& em {
				font-family: 'vd-ita';
			}
		}
	}
}


.one-columns{
	width: calc( ( 100% - (2 * var(--esp-small) ) ) );
	margin-left: var(--esp-small);
}

.two-columns{
	// width: 50%;
	width: calc( ( 100% - (3 * var(--esp-small) ) ) / 2 );	// --card-article--title--marge
	margin-left: var(--esp-small);
}

.four-columns{
	width: calc( ( 100% - (5 * var(--esp-small)) ) / 4 );	// --card-article--esp
	margin-left: var(--esp-small);
}

.five-columns{
	width: calc( ( 100% - (6 * var(--esp-small)) ) / 5 );	// --card-article--esp
	margin-left: var(--esp-small);
}

.six-columns{
	width: calc( ( 100% - (7 * var(--esp-small)) ) / 6 );	// --card-article--esp
	margin-left: var(--esp-small);
}

@media (orientation: landscape) {  
.CardArticles{
	margin-bottom: calc(var(--esp-big) + var(--header-filter-font));
}

}



</style>
